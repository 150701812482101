const { ethers } = require("ethers")
const callbacks = {}

export default function Transactor(providerOrSigner, gasPrice) {
  if (typeof providerOrSigner !== "undefined") {
    return async (tx, callback) => {
      let signer
      let provider
      if (ethers.Signer.isSigner(providerOrSigner) === true) {
        provider = providerOrSigner.provider
        signer = providerOrSigner
      } else if (providerOrSigner._isProvider) {
        provider = providerOrSigner
        signer = providerOrSigner.getSigner()
      }

      try {
        let result
        if (tx instanceof Promise) {
          result = await tx
        } else {
          if (!tx.gasPrice) {
            tx.gasPrice = gasPrice || ethers.utils.parseUnits("4.1", "gwei");
          }
          if (!tx.gasLimit) {
            tx.gasLimit = ethers.utils.hexlify(3000000)
          }
          result = await signer.sendTransaction(tx)
        }
        if (callback) {
          callbacks[result.hash] = callback
          const txResult = await tx
          const listeningInterval = setInterval(async () => {
            const currentTransactionReceipt = await provider.getTransactionReceipt(txResult.hash);
            if (currentTransactionReceipt && currentTransactionReceipt.confirmations) {
              callback({ ...txResult, ...currentTransactionReceipt })
              clearInterval(listeningInterval)
            }
          }, 500)
        }
        if (typeof result.wait === "function") {
          await result.wait()
        }
        return result
      } catch (e) {
        let message = 
          e.data && e.data.message ? e.data.message : 
          e.error && JSON.parse(JSON.stringify(e.error)).body ? JSON.parse(JSON.parse(JSON.stringify(e.error)).body).error.message : 
          e.data ? e.data : 
          JSON.stringify(e)
        if (!e.error && e.message) {
          message = e.message
        }
        try {
          let obj = JSON.parse(message)
          if (obj && obj.body) {
            let errorObj = JSON.parse(obj.body)
            if (errorObj && errorObj.error && errorObj.error.message) {
              message = errorObj.error.message
            }
          }
        } catch (e) {
          //ignore
        }
        if (callback && typeof callback === "function") {
          callback(e)
        }
      }
    }
  }
}
