import React, { useCallback, useEffect, useState } from "react"
import { Button, message, Modal } from "antd"
import { web3Modal, ethers, useUserProviderAndSigner } from "@/contract"
import { useConfigStore } from "@/store"
import { useTranslation } from "react-i18next"
import { getSimpleAddress, NoticeEmitter } from '@/tool'
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { lang, connect_wallet } = props
  const { t, i18n } = useTranslation()
  const { state, dispatch } = useConfigStore()
  const { metaMaskAddress, tronLinkAddress, payName, callBackUrl } = state
  const [injectedProvider, setInjectedProvider] = useState(null)
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, null)
  const userSigner = userProviderAndSigner.signer
  const [open, setOpen] = useState(false)

  useEffect(() => {
    !!lang && i18n.changeLanguage(lang)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    NoticeEmitter.addListener("WALLET-KEY", () => {
      setOpen(true)
    })
    return () => {
      NoticeEmitter.removeAllListeners()
    }
  })

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const _address = await userSigner.getAddress()
        dispatch({key: "metaMaskAddress", value: _address})
        const chainId = userSigner.provider._network.chainId
        dispatch({key: "chainId", value: chainId})
      }
    }
    getAddress()
  // eslint-disable-next-line
  }, [userSigner])

  useEffect(() => {
    dispatch({key: "injectedProvider", value: injectedProvider})
  // eslint-disable-next-line
  }, [injectedProvider])

  function loadWebsModalTo(key) {
    loadWeb3Modal(key)
    setOpen(false)
  }

  const loadWeb3Modal = useCallback(async (key) => {
    const _key = !!key ? key : "injected"
    const provider = await web3Modal.connectTo(_key)
    setInjectedProvider(new ethers.providers.Web3Provider(provider))

    provider.on("chainChanged", async () => {
      window.location.reload()
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    provider.on("accountsChanged", async () => {
      window.location.reload()
      setInjectedProvider(new ethers.providers.Web3Provider(provider))
    })

    provider.on("disconnect", () => {
      logoutOfWeb3Modal()
    })
  // eslint-disable-next-line
  }, [setInjectedProvider])

  useEffect(() => {
    if (web3Modal.cachedProvider && connect_wallet !== "false") {
      loadWeb3Modal("injected")
    }
  }, [loadWeb3Modal, connect_wallet])

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider()
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }

  const linkTronWeb = useCallback(async (handle) => {
    if (!!window.tronWeb) {
      let address = await window.tronWeb.defaultAddress.base58
      if (!!address) {
        await window.tronWeb.trx.getBalance(address)
        dispatch({key: "tronLinkAddress", value: address})
      } else {
        if (handle) {
          message.error(t('head.t2'))
        }
      }
    } else {
      if (handle) {
        message.error(t('head.t2'))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.tronWeb])

  useEffect(() => {
    if (!!window.tronWeb && connect_wallet !== "false") {
      linkTronWeb(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.tronWeb, connect_wallet])

  // 波场钱包登录状态
  useEffect(() => {
    async function listenTronLink() {
      window.addEventListener('message', res => {
        // 切换账号
        if (res.data.message && res.data.message.action === "setAccount") {
        }
        // 连接钱包
        if (res.data.message && res.data.message.action === "connect") {
          message.success(t('head.t7'))
        }
        // 断开连接
        if (res.data.message && res.data.message.action === "disconnect") {
          dispatch({key: "tronLinkAddress", value: ""})
        }
        if (res.data.message && res.data.message.action === "setNode") {
          window.location.reload()
        }
      })
    }
    listenTronLink()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="head-component">
      <div className="left-div"></div>
      <div className="right-div">
        <div className="back-button" onClick={() => {
          if (!!callBackUrl && callBackUrl.length > 0) {
            window.location.href = callBackUrl
          } else {
            window.history.back()
          }
        }}>
          <img src={IMAGE.BackWhite} alt="" />
          <h2>{ payName }</h2>
        </div>
        {
          !lang && <h1 className='lang-button' onClick={() => {
            if (i18n.language.startsWith("zh")) {
              i18n.changeLanguage("en")
            } else {
              i18n.changeLanguage("zh")
            }
          }}>{ i18n.language.startsWith("zh") ? 'EN' : '中' }</h1>
        }
        {
          connect_wallet === "false" ? 
            <></> : 
          !metaMaskAddress && !tronLinkAddress ? 
            <Button className="connect-button" onClick={() => setOpen(true)}>{t("head.t1")}</Button>
          : 
            <div className="wallet-button" onClick={() => setOpen(true)}>
              {
                !!metaMaskAddress && <div className="wallet-item">
                  <img src={IMAGE.Metamask} alt="" />
                  <h1>{ getSimpleAddress(metaMaskAddress) }</h1>
                </div>
              }
              {
                !!metaMaskAddress && !!tronLinkAddress && <div className="line"></div>
              }
              {
                !!tronLinkAddress && <div className="wallet-item">
                  <img src={IMAGE.Tronlink} alt="" />
                  <h1>{ getSimpleAddress(tronLinkAddress) }</h1>
                </div>
              }
              <img src={IMAGE.arrow} alt="" className="arrow" />
              <img src={IMAGE.arrow3} alt="" className="arrow-white" />
            </div>
        }
      </div>
      <Modal centered open={open} footer={null} closable={false} onCancel={() => setOpen(false)} className="wallet-modal">
        <div className="tool">
          <h1>{t("head.t1")}</h1>
          <img src={IMAGE.close} alt="" onClick={() => setOpen(false)} />
        </div>
        <div className="list">
          <div className="item" style={{ backgroundColor: !!metaMaskAddress ? '#F2F2F2' : 'white' }} onClick={() => loadWebsModalTo("injected")}>
            <img src={IMAGE.Metamask} alt="" />
            <h1>{t("head.t3")}</h1>
            {
              !!metaMaskAddress && <h2>{t("head.t6")}</h2>
            }
          </div>
          <div className="item" style={{ backgroundColor: !!tronLinkAddress ? '#F2F2F2' : 'white' }} onClick={() => {
            linkTronWeb(true)
            setOpen(false)
          }}>
            <img src={IMAGE.Tronlink} alt="" />
            <h1>{t("head.t4")}</h1>
            {
              !!tronLinkAddress && <h2>{t("head.t6")}</h2>
            }
          </div>
          {/* <div className="item" onClick={() => loadWebsModalTo("walletconnect")}>
            <img src={IMAGE.WalletConnect} alt="" />
            <h1>{t("head.t5")}</h1>
          </div> */}
        </div>
      </Modal>
    </div>
  )
}
export default IndexComponent
