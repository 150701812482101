import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { Modal, message } from "antd"
import QRCode from "qrcode.react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { IMAGE } from "@/assets"
import { getQueryVariable } from '@/tool'
import { useTranslation } from "react-i18next"
import { Head } from "@/component"
import { get } from "@/service"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [tokenOpen, setTokenOpen] = useState(false)
  const [tokenSelect, setTokenSelect] = useState(-1)
  const [networkOpen, setNetworkOpen] = useState(false)
  const [networkSelect, setNetworkSelect] = useState(-1)
  const apiKey = getQueryVariable('apiKey')
  const sign = getQueryVariable('sign')
  const timestamp = getQueryVariable('timestamp')
  const merchantAddressId = getQueryVariable('merchantAddressId')
  const demo = getQueryVariable('demo')

  useEffect(() => {
    if (!!apiKey && !!sign && !!timestamp && !!merchantAddressId) {
      getHeaders(false)
    } else if (!!demo) {
      getHeaders(true)
    }
  // eslint-disable-next-line
  }, [apiKey, sign, timestamp, merchantAddressId])

  function getHeaders(isDemo) {
    const headers = isDemo ? {
      'Content-Type': 'application/json',
    } : {
      'Content-Type': 'application/json',
      'FP-API-KEY': apiKey || "",
      'FP-SIGN': sign || "",
      'FP-TIMESTAMP': timestamp || ""
    }
    localStorage.setItem("headers", JSON.stringify(headers))
    getData(isDemo)
  }

  async function getData(isDemo) {
    try {
      const resp = await get("paymentPage/index", isDemo ? {
        demo: demo
      } : {
        merchantAddressId: merchantAddressId || "",
        uri: '/charge',
      })
      const name = resp.name
      const url = resp.ppUrl
      setName(name)
      setUrl(url)
      const wallets = resp.wallets
      wallets.forEach(element => {
        const filterToken = element.tokens.filter(function(token) {
          return token.contract === null;
        })
        element.icon = filterToken[0].icon
      })
      setData(wallets)
    } catch (error) {
      message.error(JSON.stringify(error))
    }
  }

  return (
    <div className="charge-page">
      <Head />
      <div className="leftDiv">
        <div className="backDiv" onClick={() => {
          if (!!url && url.length > 0) {
            window.location.href = url
          } else {
            window.history.back()
          }
        }}>
          <img src={IMAGE.BackWhite} alt="" />
          <p>{`${t("charge.t1")} ${name}`}</p>
        </div>
        <div className="logoDiv">
          <p>{ t("charge.t2") }</p>
          <img src={IMAGE.LogoWhite} alt="" />
        </div>
      </div>
      <div className="rightDiv">
        <div className='stepDiv'>
          <div className='number'>1</div>
          <p className='sessionTitle'>{ t("charge.t3") }</p>
        </div>
        <div className='selectContainer' style={{ borderLeftColor: networkSelect === -1 ? 'rgba(0, 0, 0, 0.2)' : 'black' }}>
          <p className='selectTitle'>{ t("charge.t4") }</p>
          <div className='selectDiv' onClick={() => setNetworkOpen(true)}>
            <img src={networkSelect === -1 ? IMAGE.Network : data[networkSelect]?.icon} alt="" className='select-icon' />
            <p className='select-token'>{
              networkSelect === -1 ? t("charge.t12") : data[networkSelect]?.chain
            }</p>
            <img src={IMAGE.SelectIcon} alt="" className='select-tag' />
          </div>
          <p className='selectTitle' style={{ opacity: networkSelect === -1 ? '0.2' : '1' }}>{ t("charge.t5") }</p>
          <div className='selectDiv' style={{ opacity: networkSelect === -1 ? '0.2' : '1' }} onClick={() => setTokenOpen(networkSelect !== -1)}>
            <img src={tokenSelect === -1 ? IMAGE.Token : data[networkSelect]?.tokens[tokenSelect]?.icon} alt="" className='select-icon' />
            <p className='select-token'>{ tokenSelect === -1 ? t("charge.t13") : data[networkSelect]?.tokens[tokenSelect]?.symbol }</p>
            <img src={IMAGE.SelectIcon} alt="" className='select-tag' />
          </div>
        </div>
        <div className='stepDiv' style={{ opacity: networkSelect === -1 ? '0.2' : '1' }}>
          <div className='number'>2</div>
          <p className='sessionTitle'>{ t("charge.t6") }</p>
        </div>
        {
          networkSelect !== -1 && <div className='copyContainer'>
          <p className='copyText'>{ data[networkSelect]?.address }</p>
          <CopyToClipboard text={data[networkSelect]?.address} onCopy={() => message.success(t("charge.t8"))}>
            <div className='copyButton'>{ t("charge.t7") }</div>
          </CopyToClipboard>
        </div>
        }
        <div className='descDiv'>
        {
          networkSelect !== -1 && <div className='qrcodeDiv'>
            <QRCode value={data[networkSelect]?.address} size={180} fgColor="#000000" />
          </div>
        }
        {
          tokenSelect !== -1 && <div className='contentDiv'>
            <p>{ t("charge.t9") } <span>{data[networkSelect]?.tokens[tokenSelect]?.minCharge} {data[networkSelect]?.tokens[tokenSelect]?.symbol}</span> { t("charge.t10") }</p><br /><br />
            <p>{ t("charge.t11") }</p>
          </div>
        }
        </div>
      </div>
      <Modal centered open={networkOpen} footer={null} destroyOnClose={true} closable={false} wrapClassName="payment-modal-class" onCancel={() => setNetworkOpen(false)}>
        <div className='toolContainer'>
          <p className='title'>{ t("charge.t4") }</p>
          <img src={IMAGE.close} alt="" className="close-button" onClick={() => setNetworkOpen(false)} />
        </div>
        <div className='select-list'>
        {
          data.map((item, index) => {
            return <div key={index} className='select-item' style={{backgroundColor: networkSelect === index ? 'rgba(0, 0, 0, 0.1)' : 'white'}} onClick={() => {
              setNetworkSelect(index)
              setTokenSelect(-1)
              setNetworkOpen(false)
            }}>
              <img src={item.icon} alt="" className='select-icon' />
              <p className='select-token'>{ item.chain }</p>
              {
                networkSelect === index && <img src={IMAGE.SelectTag} alt="" className='select-tag' />
              }
            </div>
          })
        }
        </div>
      </Modal>
      <Modal centered open={tokenOpen} footer={null} destroyOnClose={true} closable={false} wrapClassName="payment-modal-class" onCancel={() => setTokenOpen(false)} >
        <div className='toolContainer'>
          <p className='title'>{ t("charge.t5") }</p>
          <img src={IMAGE.close} alt="" className="close-button" onClick={() => setTokenOpen(false)} />
        </div>
        <div className='select-list'>
        {
          data[networkSelect]?.tokens.map((item, index) => {
            return <div key={index} className='select-item' style={{backgroundColor: tokenSelect === index ? 'rgba(0, 0, 0, 0.1)' : 'white', display: item.enabled ? 'flex' : 'none'}} onClick={() => {
              setTokenSelect(index)
              setTokenOpen(false)
            }}>
              <img src={item.icon} alt="" className='select-icon' />
              <p className='select-token'>{ item.symbol }</p>
              {
                tokenSelect === index && <img src={IMAGE.SelectTag} alt="" className='select-tag' />
              }
            </div>
          })
        }
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(IndexPage)
