import WalletConnectProvider from "@walletconnect/web3-provider"
import Web3Modal from "web3modal"
import { INFURAID, BSC_RPCURL, ETH_RPCURL } from "@/assets/wallet"

const web3Modal = new Web3Modal({
  network: "mainnet", 
  cacheProvider: true, 
  theme: "light",
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: INFURAID,
        rpc: {
          1: ETH_RPCURL,
          5: ETH_RPCURL,
          56: BSC_RPCURL,
          97: BSC_RPCURL,
        },
      }
    }
  }
})

export default web3Modal