import React, { createContext, useReducer, useContext } from "react"
import reducer from "./reducer"
import { ethers } from "@/contract"
import { BSC_RPCURL, ETH_RPCURL } from "@/assets/wallet"

export const initState = {
  metaMaskAddress: "",
  tronLinkAddress: "",
  injectedProvider: undefined,
  chainId: 0,
  network: undefined,
  payName: "",
  callBackUrl: "",
  bscLocalProvider: new ethers.providers.StaticJsonRpcProvider(BSC_RPCURL),
  ethLocalProvider: new ethers.providers.StaticJsonRpcProvider(ETH_RPCURL),
}

const ConfigCtx = createContext(null)

export const Provider = props => {
  const [state, dispatch] = useReducer(reducer, initState)
  return <ConfigCtx.Provider value={{ state, dispatch }}>{ props.children }</ConfigCtx.Provider>
}

export const useConfigStore = () => useContext(ConfigCtx)