export const INFURAID = "a97b8d47bc8a40b981f71d81b584d1a5"

// -------------------- 正式链 --------------------

export const BSC_CHAINID = 56
export const BSC_NETWORKID = 0x38
export const BSC_RPCURL = "https://bsc-dataseed4.defibit.io"
export const ETH_CHAINID = 1
export const ETH_NETWORKID = 0x1
export const ETH_RPCURL = `https://mainnet.infura.io/v3/${INFURAID}`

// -------------------- 测试链 --------------------

// export const BSC_CHAINID = 97
// export const BSC_NETWORKID = 0x61
// export const BSC_RPCURL = "https://data-seed-prebsc-1-s2.binance.org:8545"
// export const ETH_CHAINID = 5
// export const ETH_NETWORKID = 0x5
// export const ETH_RPCURL = `https://goerli.infura.io/v3/${INFURAID}`