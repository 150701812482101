import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom"
import { Modal, message, Progress, Button, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons'
import QRCode from "qrcode.react"
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { IMAGE } from "@/assets"
import { getQueryVariable, NoticeEmitter, toLocaleString } from '@/tool'
import { useTranslation } from "react-i18next"
import { useConfigStore } from "@/store"
import { Head } from "@/component"
import { get, post } from "@/service"
import { useUserProviderAndSigner, Transactor, useContractLoader, useContractConfig } from "@/contract"
import "./styles.less"

const TOTALSWAPCOUNT = 60

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  // 钱包
  const { state, dispatch } = useConfigStore()
  const { metaMaskAddress, tronLinkAddress, chainId} = state
  const userProviderAndSigner = useUserProviderAndSigner(state.injectedProvider, null)
  const userSigner = userProviderAndSigner.signer
  const contractConfig = useContractConfig()
  const writeContracts = useContractLoader(userSigner, contractConfig, 56)
  const tx = Transactor(userSigner)
  console.log("writeContracts:", writeContracts)
  // 参数
  const apiKey = getQueryVariable('apiKey')
  const sign = getQueryVariable('sign')
  const timestamp = getQueryVariable('timestamp')
  const demo = getQueryVariable('demo')
  const network = getQueryVariable('network')
  const token = getQueryVariable('token')
  const lang = getQueryVariable('lang')
  const merchantOrderId = getQueryVariable('merchantOrderId')
  const merchantOrderAmount = getQueryVariable('merchantOrderAmount')
  const merchantOrderDesc = getQueryVariable('merchantOrderDesc')
  const merchantOrderExpireTime = getQueryVariable('merchantOrderExpireTime')
  const connect_wallet = getQueryVariable('connect_wallet')
  // 询价
  const swapTimeRef = useRef()
  const [swapCount, setSwapCount] = useState(0)
  const [swapPercent, setSwapPercent] = useState(0)
  const [swapReload, setSwapReload] = useState(false)
  // 锁
  const lockTimeRef = useRef()
  const [totalLockCount, setTotalLockCount] = useState(0)
  const [lockCount, setLockCount] = useState(0)
  const [lockPercent, setLockPercent] = useState(0)
  const [lockLoading, setLockLoading] = useState(false)
  // 数据
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [payAmount, setPayAmount] = useState("-")
  const [price, setPrice] = useState(0)
  const [reload, setReload] = useState(false)
  const [ciclrReload, setCicleReload] = useState(false)
  const [networkOpen, setNetworkOpen] = useState(false)
  const [networkSelect, setNetworkSelect] = useState(-1)
  const [networkLock, setNetworkLock] = useState(false)
  const [tokenOpen, setTokenOpen] = useState(false)
  const [tokenSelect, setTokenSelect] = useState(-1)
  const [tokenLock, setTokenLock] = useState(false)
  const [payLoading, setPayLoading] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)
  const [cicle, setCicle] = useState(false)
  const symbol = !!data && data.length > 0 && networkSelect !== -1 && tokenSelect !== -1 && !!data[networkSelect]?.tokens[tokenSelect]?.symbol ? data[networkSelect]?.tokens[tokenSelect]?.symbol : ""
  const networkNormal = networkSelect !== -1 || tokenSelect !== -1 || `${chainId}` === data[networkSelect]?.chainId || data[networkSelect]?.chain === 'TRON' ? true : false

  useEffect(() => {
    if (swapCount > 0) {
      swapTimeRef.current = setTimeout(() => {
        setSwapCount(swapCount - 1)
      }, 1000)
    } else if (swapCount === 0) {
      setSwapReload(!swapReload)
    }
    return () => {
      clearTimeout(swapTimeRef.current)
    }
  // eslint-disable-next-line
  }, [swapCount])

  useEffect(() => {
    const _swapPercent = parseFloat(swapCount / TOTALSWAPCOUNT).toFixed(2) * 100
    setSwapPercent(_swapPercent)
  }, [swapCount])

  useEffect(() => {
    if (lockCount > 0) {
      lockTimeRef.current = setTimeout(() => {
        setLockCount(lockCount - 1)
      }, 1000)
    } else if (lockCount === 0) {
      setTimeout(() => {
        setReload(!reload)
      }, 2000)
    }
    return () => {
      clearTimeout(lockTimeRef.current)
    }
  // eslint-disable-next-line
  }, [lockCount])

  useEffect(() => {
    if (!!totalLockCount) {
      const _lockPercent = parseFloat(lockCount / totalLockCount).toFixed(2) * 100
      setLockPercent(_lockPercent)
    }
  }, [lockCount, totalLockCount])

  useEffect(() => {
    if (!!apiKey && !!sign && !!timestamp && !!merchantOrderExpireTime) {
      getHeaders(false)
    } else if (!!demo) {
      getHeaders(true)
    }
  // eslint-disable-next-line
  }, [apiKey, sign, timestamp, merchantOrderExpireTime, reload])

  function getHeaders(isDemo) {
    const headers = isDemo ? {
      'Content-Type': 'application/json',
    } : {
      'Content-Type': 'application/json',
      'FP-API-KEY': apiKey || "",
      'FP-SIGN': sign || "",
      'FP-TIMESTAMP': timestamp || ""
    }
    localStorage.setItem("headers", JSON.stringify(headers))
    getData(isDemo)
    setCicle(true)
  }

  async function getData(isDemo) {
    try {
      setLoading(true)
      const resp = await get("paymentPage/index", isDemo ? {
        demo: demo
      } : {
        merchantOrderId: merchantOrderId,
        merchantOrderAmount: merchantOrderAmount,
        uri: '/order',
        merchantOrderExpireTime: merchantOrderExpireTime
      })
      setLoading(false)
      const name = resp.name
      const url = resp.ppUrl
      const priceLockSecond = resp.priceLockSecond
      const requestedDepositOrderInfo = resp.requestedDepositOrderInfo
      setName(name)
      setUrl(url)
      dispatch({key: "payName", value: name})
      dispatch({key: "callBackUrl", value: url})
      setTotalLockCount(priceLockSecond)
      setOrder(requestedDepositOrderInfo)
      const price = requestedDepositOrderInfo.price
      const startTimestamp = requestedDepositOrderInfo.lastPriceLockedTime
      const startMoment = moment(startTimestamp)
      const finishMoment = startMoment.add(priceLockSecond, 'seconds')
      const currentMoment = moment()
      if (requestedDepositOrderInfo.status === "PRICE_LOCKED") {
        const _lockCount = finishMoment.diff(currentMoment, 'seconds')
        const __lockCount = _lockCount > 0 ? _lockCount : 0
        setLockCount(__lockCount)
        setPrice(price)
      }
      const wallets = resp.wallets
      wallets.forEach(element => {
        const filterToken = element.tokens.filter(function(token) {
          return token.contract === null;
        })
        element.icon = filterToken[0].icon
      })
      const chain = requestedDepositOrderInfo.chain
      if (!!chain) {
        const _networkSelect = wallets.findIndex(obj => obj.chain === chain) 
        setNetworkSelect(_networkSelect)
        const asset = requestedDepositOrderInfo.asset
        if (!!asset && _networkSelect >= 0) {
          const _tokens = wallets[_networkSelect]?.tokens
          const _tokenSelect = _tokens.findIndex(obj => obj.symbol === asset)
          setTokenSelect(_tokenSelect)
        }
      }
      if (!!network) {
        const _networkSelect = wallets.findIndex(obj => obj.chain === network)
        setNetworkSelect(_networkSelect)
        setNetworkLock(_networkSelect >= 0)
        if (!!token && _networkSelect >= 0) {
          const _tokens = wallets[_networkSelect]?.tokens
          const _tokenSelect = _tokens.findIndex(obj => obj.symbol === token)
          setTokenSelect(_tokenSelect)
          setTokenLock(_tokenSelect >= 0)
        }
      }
      setData(wallets)
    } catch (error) {
      setLoading(false)
      message.error(JSON.stringify(error))
    }
  }

  useEffect(() => {
    async function cycleData(isDemo) {
      try {
        const resp = await get("paymentPage/index", isDemo ? {
          demo: demo
        } : {
          merchantOrderId: merchantOrderId,
          merchantOrderAmount: merchantOrderAmount,
          uri: '/order',
          merchantOrderExpireTime: merchantOrderExpireTime
        })
        const requestedDepositOrderInfo = resp.requestedDepositOrderInfo
        if (requestedDepositOrderInfo.status !== "CREATED" && requestedDepositOrderInfo.status !== "PRICE_LOCKED") {
          setSuccessOpen(true)
        } else {
          setTimeout(() => {
            setCicleReload(!ciclrReload)
          }, 10000)
        }
      } catch (error) {
        setTimeout(() => {
          setCicleReload(!ciclrReload)
        }, 10000)
      }
    }
    if (cicle) {
      if (!!apiKey && !!sign && !!timestamp && !!merchantOrderExpireTime) {
        cycleData(false)
      } else {
        cycleData(true)
      }
    }
  // eslint-disable-next-line
  }, [cicle, ciclrReload])

  // 询价
  useEffect(() => {
    async function quote() {
      if (symbol === "USDT" || symbol === "USDC") {
        setPrice(merchantOrderAmount)
        setPayAmount(merchantOrderAmount)
      } else if (order?.status !== "PRICE_LOCKED") {
        try {
          const resp = await get("deposit_order/quote", {
            amount: merchantOrderAmount,
            asset: symbol
          })
          setPrice(resp.price)
          setPayAmount(resp.assetAmount)
          setSwapCount(TOTALSWAPCOUNT)
        } catch (error) {
          message.error(JSON.stringify(error))
        }
      }
    }
    if (!!symbol && !!merchantOrderAmount) {
      quote()
    }
  // eslint-disable-next-line
  }, [symbol, merchantOrderAmount, order, swapReload])

  // 锁定
  async function lock() {
    try {
      setLockLoading(true)
      await post("paymentPage/depositOrder/lock", {
        chain: data[networkSelect]?.chain,
        asset: symbol,
        price: price,
        merchantOrderAmount: merchantOrderAmount,
        depositOrderId: order?.id,
        merchantOrderId: merchantOrderId,
        merchantOrderExpireTime: merchantOrderExpireTime
      })
      setLockLoading(false)
      setTimeout(() => {
        setReload(!reload)
      }, 2000)
    } catch (error) {
      setLockLoading(false)
      message.error(JSON.stringify(error))
    }
  }

  // 解锁
  async function unlock() {
    try {
      setLockLoading(true)
      post("paymentPage/depositOrder/unlock", {
        depositOrderId: order?.id,
        merchantOrderId: merchantOrderId,
        merchantOrderAmount: merchantOrderAmount,
        merchantOrderExpireTime: merchantOrderExpireTime
      })
      setLockLoading(false)
      setTimeout(() => {
        setReload(!reload)
      }, 2000)
    } catch (error) {
      setLockLoading(false)
      message.error(JSON.stringify(error))
    }
  }

  // 支付
  async function pay() {
    const chain = data[networkSelect]?.chain
    const address = data[networkSelect]?.address
    const contractAddress = data[networkSelect]?.tokens[tokenSelect]?.contract 
    const decimals = data[networkSelect]?.tokens[tokenSelect]?.decimals 
    const _decimals = Math.pow(10, decimals)
    const amount = parseFloat(payAmount)
    if (chain === "TRON") {
      if (symbol === "TRX") {
        try {
          setPayLoading(true)
          const resp = await window.tronWeb.trx.sendTransaction({
            to: address,
            amount: amount * _decimals
          })
          setPayLoading(false)
          console.log("resp", chain, symbol, resp)
          submit(resp)
        } catch (error) {
          setPayLoading(false)
          message.error(t("order.t29"))
        }
      } else {
        try {
          const { abi } = await window.tronWeb.trx.getContract(contractAddress)
          const contract = window.tronWeb.contract(abi.entrys, contractAddress)
          setPayLoading(true)
          const resp = await contract.methods.transfer(address, amount * _decimals).send()
          setPayLoading(false)
          console.log("resp", chain, symbol, resp)
          submit(resp)
        } catch (error) {
          setPayLoading(false)
          message.error(t("order.t29"))
        }
      }
    } else {
      const _amount = new BigNumber(amount)
      const __amount = BigNumber(_decimals).times(_amount)
      if (!contractAddress) {
        try {
          setPayLoading(true)
          const resp = await tx({
            to: address,
            value: '0x' + __amount.toString(16),
            gasLimit: 32000,
          })
          setPayLoading(false)
          console.log("resp", chain, symbol, resp)
          submit(resp?.hash)
        } catch (error) {
          setPayLoading(false)
          message.error(t("order.t29"))
        }
      } else {
        try {
          setPayLoading(true)
          if (!!writeContracts[symbol]) {
            const resp = await tx(writeContracts[symbol].transfer(address, '0x' + __amount.toString(16), {
              gasLimit: 80000,
            }))
            setPayLoading(false)
            console.log("resp", chain, symbol, resp)
            submit(resp?.hash)
          } else {
            message.error(t("order.t29"))
          }
        } catch (error) {
          setPayLoading(false)
          message.error(t("order.t29"))
        }
        
      }
    }
  }

  function submit(txid) {
    if (!!txid) {
      setSuccessOpen(true)
    } else {
      message.error(t("order.t29"))
    }
  }

  return (
    <div className="order-page">
      <Head lang={lang} connect_wallet={connect_wallet} />
      {/* 左边 */}
      <div className="leftDiv">
        <div className="backDiv" onClick={() => {
          if (!!url && url.length > 0) {
            window.location.href = url
          } else {
            window.history.back()
          }
        }}>
          <img src={IMAGE.BackWhite} alt="" />
          <h2>{`${t("order.t1")} ${name}`}</h2>
        </div>
        <h2 className="desc">{ merchantOrderDesc }</h2>
        <h1 className="amount">{`${toLocaleString(merchantOrderAmount)} USDT`}</h1>
        <div className="item">
          <h2 className='key'>{ t("order.t15") }</h2>
          <h2 className='value'>{ name }</h2>
        </div>
        <div className="item">
          <h2 className='key'>{ t("order.t16") }</h2>
          <CopyToClipboard text={merchantOrderId} onCopy={() => message.success(t("order.t8"))}>
            <h2 className='value' style={{ cursor: 'pointer' }}><img src={IMAGE.copy} alt="" />{ merchantOrderId }</h2>
          </CopyToClipboard>
        </div>
        <div className='flex'></div>
        <div className="logoDiv">
          <h2>{ t("order.t2") }</h2>
          <img src={IMAGE.LogoWhite} alt="" />
        </div>
      </div>
      {/* 右边 */}
      <div className="rightDiv">
        <h1 className='title'>{ t("order.t17") }</h1>
        {
          loading && <Spin indicator={<LoadingOutlined spin className='load-spin' />} />
        }
        {
          networkSelect !== -1 && tokenSelect !== -1 && <h2 className='sub-title'>{ t("order.t18") }</h2>
        }
        {/* 支付信息 */}
        {
          networkSelect !== -1 && tokenSelect !== -1 && <div className='amount-div'>
            <h1>{`${toLocaleString(payAmount)} ${symbol}`}</h1>
            {
              !!symbol && symbol !== "USDT" && symbol !== "USDC" && <>
              {
                swapCount === 0 && order?.status !== "PRICE_LOCKED" ? <Spin indicator={<LoadingOutlined spin className='spin' />} /> : <h2>{`1 ${symbol} = ${toLocaleString(price)} USDT`}</h2>
              }
              </> 
            }
            {
              !!symbol && symbol !== "USDT" && symbol !== "USDC" && order?.status !== "PRICE_LOCKED" && <div className='countdown-div'>
                <Progress type="circle" strokeWidth={4} percent={swapPercent} strokeColor="#FAAF00" format={() => {
                  return swapCount > 0 ? `${swapCount}s` : '-'
                }} />
              </div>
            }
          </div>
        }
        {
          networkSelect !== -1 && tokenSelect !== -1 && !!symbol && symbol !== "USDT" && symbol !== "USDC" && <>
          {
            swapCount === 0 && order?.status !== "PRICE_LOCKED" ? <Spin indicator={<LoadingOutlined spin className='swap-spin' />} /> : <h2 className='swap-txt'>{`1 ${symbol} = ${toLocaleString(price)} USDT`}</h2>
          }
          </>
        }
        {/* 区块链网络 */}
        <div className='select-container'>
          <h2 className='type'>{ t("order.t4") }</h2>
          <div className={`select-div ${networkLock || order?.status === "PRICE_LOCKED" ? 'disable' : ''}`} onClick={() => {
            if (!networkLock && order?.status !== "PRICE_LOCKED") {
              setNetworkOpen(true)
            }
          }}>
            <img src={networkSelect === -1 ? IMAGE.Network : data[networkSelect]?.icon} alt="" className='icon' />
            <h1 className='name'>{
              networkSelect === -1 ? t("order.t12") : data[networkSelect]?.chain
            }</h1>
            <img src={networkLock || order?.status === "PRICE_LOCKED" ? IMAGE.arrow2 : IMAGE.arrow} alt="" className='tag' />
          </div>
        </div>
        {/* 代币 */}
        <div className='select-container'>
          <h2 className='type' style={{ opacity: networkSelect === -1 ? '0.2' : '1' }}>{ t("order.t5") }</h2>
          <div className={`select-div ${tokenLock || order?.status === "PRICE_LOCKED" ? 'disable' : ''}`} style={{ opacity: networkSelect === -1 ? '0.2' : '1' }} onClick={() => {
            if (!tokenLock && order?.status !== "PRICE_LOCKED") {
              setTokenOpen(networkSelect !== -1)
            }
          }}>
            <img src={tokenSelect === -1 ? IMAGE.Token : data[networkSelect]?.tokens[tokenSelect]?.icon} alt="" className='icon' />
            <h1 className='name'>{ tokenSelect === -1 ? t("order.t13") : symbol }</h1>
            <img src={networkLock || order?.status === "PRICE_LOCKED" ? IMAGE.arrow2 : IMAGE.arrow} alt="" className='tag' />
          </div>
        </div>
        {
          networkSelect !== -1 && tokenSelect !== -1 && <>
            {/* 锁 */}
            {
              order?.status === "PRICE_LOCKED" && <div className='unlock-div'>
                <div className='tool'>
                  {
                    lockLoading ? <Spin indicator={<LoadingOutlined spin className='lock-spin' />} /> : <div className='unlock-button' onClick={() => unlock()}>
                      <img src={IMAGE.unlock} alt="" />
                      <h1>{ t("order.t22") }</h1>
                    </div>
                  }
                  <h2>{ t("order.t23", { count: lockCount }) }</h2>
                </div>
                <div className='countdown-div'>
                  <Progress type="line" strokeWidth={6} percent={lockPercent} strokeColor="#FAAF00" showInfo={false} />
                </div>
              </div>
            }
            {/* 按钮 */}
            {
              order?.status !== "PRICE_LOCKED" && symbol !== "USDT" && symbol !== "USDC" ? 
              <Button className="action-button" 
                disabled={payAmount === "-" || swapCount === 0}
                loading={lockLoading}
                onClick={() => lock()}>
                  <img src={IMAGE.lock} alt="" />{ t("order.t20") }
              </Button>
              : (!metaMaskAddress && data[networkSelect]?.chain !== 'TRON') || (!tronLinkAddress && data[networkSelect]?.chain === 'TRON') ? 
                connect_wallet !== "false" ?
                  <Button className="action-button" onClick={() => {
                    NoticeEmitter.emit("WALLET-KEY")
                  }}>{ t("order.t19") }</Button>
                : <></>
              : <Button className="action-button" 
                  disabled={!networkNormal || !(order?.status === "CREATED" || order?.status === "PRICE_LOCKED")} 
                  loading={payLoading}
                  onClick={() => pay()}>
                    { `${t("order.t21")} ${toLocaleString(payAmount)} ${symbol}` }
                </Button> 
            }
            {/* 网络 */}
            {
              !networkNormal && (order?.status === "PRICE_LOCKED" || symbol === "USDT" || symbol === "USDC") && <h2 className='disable-text'>{ t("order.t24") }</h2>
            }
            {
              (order?.status === "PRICE_LOCKED" || symbol === "USDT" || symbol === "USDC") && <>
                {
                  connect_wallet !== "false" ?
                    <div className='or-div'>
                      <div className='line'></div>
                      <h2>{ t("order.t25") }</h2>
                      <div className='line'></div>
                    </div>
                  :
                   <></>
                }
                <div className='statistics'>
                  <h2>{ t("order.t26", { chain: data[networkSelect]?.chain }) }</h2>
                  <h1>{`${toLocaleString(payAmount)} ${symbol}`}</h1>
                  <h2>{ t("order.t27", { chain: data[networkSelect]?.chain }) }</h2>
                </div>
                <div className='copy-div'>
                  <h2>{ data[networkSelect]?.address }</h2>
                  <CopyToClipboard text={data[networkSelect]?.address} onCopy={() => message.success(t("order.t8"))}>
                    <Button className='copy-button'>{ t("order.t7") }</Button>
                  </CopyToClipboard>
                </div>
                <div className='qrcode-div'>
                  <QRCode value={data[networkSelect]?.address} size={180} fgColor="#000000" />
                </div>
              </>
            }
          </>
        }
        <div className="logoDiv">
          <h2>{ t("order.t2") }</h2>
          <img src={IMAGE.LogoBlack} alt="" />
        </div>
      </div>
      <Modal centered open={networkOpen} footer={null} destroyOnClose={true} closable={false} wrapClassName="payment-modal-class" onCancel={() => setNetworkOpen(false)}>
        <div className='toolContainer'>
          <h1 className='title'>{ t("order.t4") }</h1>
          <img src={IMAGE.close} alt="" className="close-button" onClick={() => setNetworkOpen(false)} />
        </div>
        <div className='select-list'>
        {
          data.map((item, index) => {
            return <div key={index} className='select-item' style={{backgroundColor: networkSelect === index ? '#F2F2F2' : 'white'}} onClick={() => {
              setNetworkSelect(index)
              setTokenSelect(-1)
              setNetworkOpen(false)
            }}>
              <img src={item.icon} alt="" className='select-icon' />
              <h1 className='select-token'>{ item.chain }</h1>
              {
                networkSelect === index && <img src={IMAGE.SelectTag} alt="" className='select-tag' />
              }
            </div>
          })
        }
        </div>
      </Modal>
      <Modal centered open={tokenOpen} footer={null} destroyOnClose={true} closable={false} wrapClassName="payment-modal-class" onCancel={() => setTokenOpen(false)} >
        <div className='toolContainer'>
          <h1 className='title'>{ t("order.t5") }</h1>
          <img src={IMAGE.close} alt="" className="close-button" onClick={() => setTokenOpen(false)} />
        </div>
        <div className='select-list'>
        {
          data[networkSelect]?.tokens.map((item, index) => {
            return <div key={index} className='select-item' style={{backgroundColor: tokenSelect === index ? '#F2F2F2' : 'white', display: item.enabled ? 'flex' : 'none'}} onClick={() => {
              setTokenSelect(index)
              setTokenOpen(false)
            }}>
              <img src={item.icon} alt="" className='select-icon' />
              <h1 className='select-token'>{ item.symbol }</h1>
              {
                tokenSelect === index && <img src={IMAGE.SelectTag} alt="" className='select-tag' />
              }
            </div>
          })
        }
        </div>
      </Modal>
      <Modal centered open={successOpen} footer={null} destroyOnClose={false} closable={false} wrapClassName="payment-modal-class">
        <h1 className='success-title'>{ t("order.t30") }</h1>
        <h2 className='success-content'>{ t("order.t11") }</h2>
        <Button className="ok-button" onClick={() => {
          setSuccessOpen(false)
          if (!!url && url.length > 0) {
            window.location.href = url
          } else {
            window.history.back()
          }
        }}>{ t("order.t31") }</Button>
      </Modal>
    </div>
  );
};

export default withRouter(IndexPage)
