import { ethers, utils } from "ethers"
import { useUserProviderAndSigner, useContractLoader } from "eth-hooks"
import web3Modal from "./helpers/web-modal"
import { useContractConfig } from "./helpers/use-contract-config"
import { default as Transactor } from "./helpers/transactor"

const { isAddress } = utils

export { 
  web3Modal, ethers, 
  useContractConfig, useUserProviderAndSigner, useContractLoader, Transactor, isAddress
}