import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@/i18n"
import { Order, Charge, NotFound } from "@/page"
import "./App.less"

const App = () => {

  return (
    <Router>
      <Switch>
        <Route path="/order"><Order /></Route>
        <Route path="/charge"><Charge /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
    </Router>
  )
}

export default App