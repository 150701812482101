import NoticeEmitter from "./notice"

export { NoticeEmitter }

export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		const element = vars[i]
		if(element.startsWith(`${variable}=`)){
			const elem = element.replace(`${variable}=`, '')
			return decodeURIComponent(elem)
		}
	}
	return(false);
}

export function getSimpleAddress(address) {
  if (!!address) {
    const _address = address.toLowerCase()
    const simpleAddress = _address.substring(0, 3) + "..." + _address.substring(_address.length - 3, _address.length)
    return simpleAddress
  } else {
    return ""
  }
}

export const toUseContractReader =  async (contracts, contractName, functionName, functionArgs = [], formatter) => {
  var _a
  const contractFunction = (_a = contracts === null || contracts === void 0 ? void 0 : contracts[contractName]) === null || _a === void 0 ? void 0 : _a[functionName]
  let newValue
  if (contractFunction) {
    if (functionArgs && functionArgs.length > 0) {
      newValue = await contractFunction(...functionArgs)
    }
    else {
      newValue = await contractFunction()
    }
    if (formatter && typeof formatter === 'function') {
      newValue = formatter(newValue)
    }
  }
  return newValue
}

export function toLocaleString(number) {
  if (!number) {
    return ""
  } else if (number === "-") {
    return "-"
  } else {
    return parseFloat(Number(number).toLocaleString(undefined, {minimumFractionDigits: 6}))
  }
}
