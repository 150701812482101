import BackWhite from "./assets/back-white.png"
import LogoWhite from "./assets/logo-white.png"
import LogoBlack from "./assets/logo-black.png"
import Token from "./assets/token.png"
import Network from "./assets/network.png"
import SelectTag from "./assets/select-tag.png"
import Metamask from "./assets/metamask.png"
import Tronlink from "./assets/tronlink.png"
import WalletConnect from "./assets/wallet-connect.png"
import close from "./assets/close.png"
import arrow from "./assets/arrow.png"
import arrow2 from "./assets/arrow-2.png"
import arrow3 from "./assets/arrow-3.png"
import copy from "./assets/copy.png"
import lock from "./assets/lock.png"
import unlock from "./assets/unlock.png"

const IMAGE = {
  BackWhite, LogoWhite, LogoBlack, Token, Network, SelectTag, Metamask, Tronlink, WalletConnect, close, arrow, arrow2, arrow3, copy, lock, unlock
}

export default IMAGE
