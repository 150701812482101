const reducer = (state, action) => {
  switch(action.key) {
    case "metaMaskAddress":
      return { ...state, metaMaskAddress: action.value }
    case "tronLinkAddress":
      return { ...state, tronLinkAddress: action.value }
    case "injectedProvider":
      return { ...state, injectedProvider: action.value }
    case "chainId":
      return { ...state, chainId: action.value }
    case "network":
      return { ...state, network: action.value }
    case "payName":
      return { ...state, payName: action.value }
    case "callBackUrl":
      return { ...state, callBackUrl: action.value }
    default:
      return state
  }
}
export default reducer